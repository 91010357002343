/* eslint-disable */
import $ from 'jquery';

import './core/migrate-mute';
import 'jquery-migrate';
import 'jquery.browser';
import '@prestashop-core/jquery.live-polyfill';
import './core/cart';
import './core/checkout';
import './core/facets';
import './core/listing';
import './core/product';
import './core/address';

import prestashop from 'prestashop';
import EventEmitter from 'events';
import { psShowHide } from './core/common';
import initEmailFields from './core/email-idn';
/* expose jQuery for modules */
window.$ = $;
window.jQuery = $;
jQuery.migrateMute = true;

// "inherit" EventEmitter
for (const i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

$(document).ready(() => {
  psShowHide();
  initEmailFields('input[type="email"]');
});
